import React from 'react'
import MainLayout from '../components/MainLayout'
import Price from '../components/pricing/price'
const Pricing = () => {
  return (
  
      <Price />
   
  )
}

export default Pricing
