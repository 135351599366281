import React from 'react'
import ApiHistory from './../components/Dashboard/ApiHistory';

const SearchHistory = () => {
  return (
    // <div>SearchHistory</div>
    <ApiHistory/>
  )
}

export default SearchHistory