import React from 'react'
import MainLayout from '../components/MainLayout'
import Layout from '../components/Dashboard/Layout'


const Dashboard = () => {
  return (
    // <MainLayout>
        <Layout />
    // </MainLayout>
  )
}

export default Dashboard