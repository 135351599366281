import React from 'react'
import ApiHistory from '../components/Dashboard/ApiHistory'
import Transaction from '../components/Dashboard/Transaction'

const TransactionHistory = () => {
  return (
    <Transaction/>
  )
}

export default TransactionHistory